/*
 * @Descripttion: 首页模块接口
 * @Author: Dyf
 * @Date: 2023-04-12
 * @LastEditors: Dyf
 * @LastEditTime: 2023-04-14
 */
import HTTP from "@utils/http";
import { PAGE_SIZE } from "@config";

const PREFIX = "/teacher/schclass/"; // 班级管理（未入驻）
const PREFIX2 = "/teacher/schclassschedule/"; // 课程表管理
const PREFIX3 = "/teacher/schclassscheduletime/"; // 课程表时间管理
const PREFIX4 = "/teacher/schcourses/"; // 教材管理
const PREFIX5 = "/teacher/schcourseschapter/"; // 章节管理
const PREFIX6 = "/teacher/plateamessage/"; // 消息通知
const PREFIX7 = "/teacher/stuuser/"; // 班级管理（已入驻）
const PREFIX8 = "/teacher/teastuexamine/"; // 待处理

/* 未入驻班级管理 */

/*******
 * @Descripttion: 班级树状列表
 * @Author: Dyf
 * @return {*}
 */
export async function $classTree() {
  try {
    return await HTTP.post(
      `${PREFIX}list`,
      {},
      { load: true, loadEl: ".table-tree" }
    );
  } catch (error) {
    return error;
  }
}
/*******
 * @Descripttion: 获取年级人员
 * @Author: ldx0.0
 * @param {number} grade_id 年级id sccla_id 班级id
 * @return {*}
 */
export async function $getGradeUserList(param) {
  try {
    return await HTTP.get(`${PREFIX}get_grade_user_list`, param );
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 修改该班级人员
 * @Author: ldx0.0
 * @param
 * @return {*}
 */
export async function $editElectiveForm(param) {
  try {
    return await HTTP.post(`${PREFIX}edit_elective_user`, param );
  } catch (error) {
    return error;
  }
}
/*******
 * @Descripttion: 新增班级
 * @Author: Dyf
 * @param {object} params 班级新增参数
 * @return {*}
 */
export async function $classAdd(params) {
  try {
    return await HTTP.post(`${PREFIX}add`, params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 编辑班级
 * @Author: Dyf
 * @param {object} params 班级编辑参数
 * @return {*}
 */
export async function $classEdit(params) {
  try {
    return await HTTP.post(`${PREFIX}edit`, params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 删除班级
 * @Author: Dyf
 * @param {number} id 班级id
 * @return {*}
 */
export async function $classDel(id) {
  try {
    return await HTTP.post(`${PREFIX}del`, { id });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 配置年级
 * @Author: Dyf
 * @param {object} params 年级配置参数
 * @return {*}
 */
export async function $gradeSet(params) {
  try {
    return await HTTP.post(`${PREFIX}edit_grade`, params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 获取年级配置信息
 * @Author: Dyf
 * @param {number} schedi_sub_grade 年级编码
 * @return {*}
 */
export async function $getGrad(schedi_sub_grade) {
  try {
    return await HTTP.post(`${PREFIX}grade_info`, { schedi_sub_grade });
  } catch (error) {
    return error;
  }
}

/* 课程表管理 */

/*******
 * @Descripttion: 获取当天课程表
 * @Author: Dyf
 * @param {Number} date 日期时间戳
 * @return {*}
 */
export async function $dayTimeTable(date) {
  try {
    return await HTTP.post(`${PREFIX2}get_class_data`, { date });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 获取课程表信息
 * @Author: Dyf
 * @param {Number} params.sccla_sch_grade 年级id
 * @param {Number} params.sccla_id 班级id
 * @param {Number} params.sccla_sch_semester  学期：1=>上~  2=>下~
 * @return {*}
 */
export async function $timeTableInfo(params) {
  try {
    return await HTTP.post(`${PREFIX2}info`, params, {
      load: true,
      loadEl: ".school-timetable--inner",
    });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 课程表内容添加
 * @Author: Dyf
 * @param {Object} params 课程表单信息
 * @return {*}
 */
export async function $timeTableAdd(params) {
  try {
    return await HTTP.post(`${PREFIX2}add`, params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 课程表内容编辑
 * @Author: Dyf
 * @param {Object} params 课程表单信息
 * @return {*}
 */
export async function $timeTableEdit(params) {
  try {
    return await HTTP.post(`${PREFIX2}edit`, params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 课程表内容清除
 * @Author: Dyf
 * @param {Number} sccla_sch_id 课程内容id
 * @return {*}
 */
export async function $timeTableColDel(sccla_sch_id) {
  try {
    return await HTTP.post(`${PREFIX2}delete`, { sccla_sch_id });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 课程表整行内容清除
 * @Author: Dyf
 * @param {Number} sccla_sch_tim_id 课程表时间id
 * @return {*}
 */
export async function $timeTableRowlDel(sccla_sch_tim_id) {
  try {
    return await HTTP.post(`${PREFIX2}del_row`, { sccla_sch_tim_id });
  } catch (error) {
    return error;
  }
}

/* 课程表时间管理 */

/*******
 * @Descripttion: 是否设置课程表时间
 * @Author: Dyf
 * @return {*}
 */
export async function $scheduleTimeIsSet() {
  try {
    return await HTTP.post(`${PREFIX3}is_exist`);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 下载课表模板
 * @Author: Dyf
 * @return {*}
 */
export async function $downloadTemplate() {
  try {
    return await HTTP.post(`${PREFIX3}importtemplate`);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 导入课程数据
 * @Author: Dyf
 * @param {object} params
 * @return {*}
 */
export async function $importTimetable(params) {
  try {
    return await HTTP.post(`${PREFIX3}import`, params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 课程表时间列表
 * @Author: Dyf
 * @param {Number} pageindex 当前页码
 * @param {Number} pagesize 每页条数
 * @return {*}
 */
export async function $scheduleTime(pageindex = 1, pagesize = PAGE_SIZE) {
  try {
    return await HTTP.post(
      `${PREFIX3}list`,
      { pagesize, pageindex },
      { load: true }
    );
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 课程表时间添加
 * @Author: Dyf
 * @param {number} starttime 开始时间
 * @param {number} endtime 结束时间
 * @return {*}
 */
export async function $scheduleTimeAdd(starttime, endtime) {
  try {
    return await HTTP.post(`${PREFIX3}add`, {
      sccla_sch_tim_starttime: starttime,
      sccla_sch_tim_endtime: endtime,
    });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 课程表时间编辑
 * @Author: Dyf
 * @param {Number} params.sccla_sch_tim_id 时间id
 * @param {Number} params.sccla_sch_tim_starttime 开始时间
 * @param {Number} params.sccla_sch_tim_endtime 结束时间
 * @return {*}
 */
export async function $scheduleTimeEidt(params) {
  try {
    return await HTTP.post(`${PREFIX3}edit`, params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 课程表时间删除
 * @Author: Dyf
 * @param {Number} sccla_sch_tim_id 时间id
 * @return {*}
 */
export async function $scheduleTimeDel(sccla_sch_tim_id) {
  try {
    return await HTTP.post(`${PREFIX3}delete`, { sccla_sch_tim_id });
  } catch (error) {
    return error;
  }
}

/* 教材管理 */

/*******
 * @Descripttion: 教材列表
 * @Author: Dyf
 * @param {object} params 筛选参数
 * @param {Number} pagesize 每页条数
 * @return {*}
 */
 export async function $textbookList(params, pagesize = PAGE_SIZE) {
  try {
    return await HTTP.post(
      `${PREFIX4}list`,
      { ...params, pagesize },
      { load: true }
    );
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 教材新增（提审）
 * @Author: Dyf
 * @param {object} params 表单参数
 * @return {*}
 */
export async function $textbookAdd(params) {
  try {
    return await HTTP.post(`${PREFIX4}add`, params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 教材编辑
 * @Author: Dyf
 * @param {object} params 表单参数
 * @return {*}
 */
export async function $textbookEdit(params) {
  try {
    return await HTTP.post(`${PREFIX4}edit`, params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 修改教材状态
 * @Author: Dyf
 * @param {number} sccou_id 教材id
 * @param {number} type 教材状态
 * @return {*}
 */
export async function $textbookStatusChange(sccou_id, type) {
  try {
    return await HTTP.post(`${PREFIX4}change_status`, { sccou_id, type });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 教材详情
 * @Author: Dyf
 * @param {number} sccou_id 教材id
 * @return {*}
 */
export async function $textbookInfo(sccou_id) {
  try {
    return await HTTP.post(`${PREFIX4}info`, { sccou_id });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 学校教材信息
 * @Author: 
 * @param {number}  grade 年级  sysub_id 科目  semester 学期  sccou_cha_pid 查询章节的时候传递  chapter_word 关键字 is_trees 是否返回树状1是 2否
 * @return {*}
 */
export async function $get_school_course_list(params) {
  try {
    return await HTTP.get(`${PREFIX4}get_school_course_list`, params);
  } catch (error) {
    return error;
  }
}

/* 章节管理 */

/*******
 * @Descripttion: 章节列表
 * @Author: Dyf
 * @param {number} sccou_id 教材id
 * @param {string} query_word 搜索关键字
 * @return {*}
 */
export async function $chaptersList(sccou_id, query_word) {
  try {
    return await HTTP.post(
      `${PREFIX5}list_data`,
      { sccou_id, query_word },
      { load: true, loadEl: ".table-tree" }
    );
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 章节添加
 * @Author: Dyf
 * @param {object} params 表单参数
 * @return {*}
 */
export async function $chaptersAdd(params) {
  try {
    return await HTTP.post(`${PREFIX5}add`, params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 章节编辑
 * @Author: Dyf
 * @param {object} params 表单参数
 * @return {*}
 */
export async function $chaptersEdit(params) {
  try {
    return await HTTP.post(`${PREFIX5}edit`, params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 筛选章节信息
 * @Author: Dyf
 * @param {number} params.grade 年级编码
 * @param {number} params.sysub_id 科目id
 * @param {number} params.sccou_id 教材id
 * @param {number} params.sccou_cha_pid 章id (查询节)
 * @param {string} params.chapter_word 章节关键字
 * @return {*}
 */
export async function $getChapters(params) {
  try {
    return await HTTP.post(`${PREFIX5}get_school_chapter`, params);
  } catch (error) {
    return error;
  }
}

/* 消息、通知 */

/*******
 * @Descripttion: 消息列表
 * @Author: Dyf
 * @param {number} create_origin 消息来源 10=>平台   30=>学校
 * @param {string} query_word 搜索关键字
 * @return {*}
 */
export async function $msgList(create_origin, query_word) {
  try {
    return await HTTP.post(`${PREFIX6}list`, { create_origin, query_word });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 学校通知列表(首页展示)
 * @Author: Dyf
 * @param {number} pageindex 当前页码
 * @param {number} pagesize 每页条数
 * @return {*}
 */
export async function $schoolMsgList(pageindex = 1, pagesize = PAGE_SIZE) {
  try {
    return await HTTP.post(
      `${PREFIX6}school_home_info`,
      { pageindex, pagesize },
      { load: true, loadEl: ".notice--inner" }
    );
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 学校通知列表(表格)
 * @Author: Dyf
 * @param {object} params
 * @param {number} pagesize 每页条数
 * @return {*}
 */
export async function $schoolMsgList2(params, pagesize = PAGE_SIZE) {
  try {
    return await HTTP.post(
      `${PREFIX6}school_home_info`,
      { ...params, pagesize },
      { load: true }
    );
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 学校通知一键已读
 * @Author: Dyf
 * @return {*}
 */
export async function $schoolMsgRead() {
  try {
    return await HTTP.post(`${PREFIX6}click_read`);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 删除学校通知
 * @Author: Dyf
 * @param {number} pltea_mes_id 消息通知id
 * @return {*}
 */
export async function $delSchoolMsg(pltea_mes_id) {
  try {
    return await HTTP.post(`${PREFIX6}delete`, { pltea_mes_id });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 消息详情
 * @Author: Dyf
 * @param {number} pltea_mes_id 消息id
 * @return {*}
 */
export async function $msgDetail(pltea_mes_id) {
  try {
    return await HTTP.post(`${PREFIX6}info`, { pltea_mes_id });
  } catch (error) {
    return error;
  }
}

/* 班级管理 */

/*******
 * @Descripttion: 班级学生列表
 * @Author: Dyf
 * @param {object} params 筛选条件
 * @param {number} pagesize 每页条数
 * @return {*}
 */
export async function $studentList(params, pagesize = PAGE_SIZE) {
  try {
    return await HTTP.post(
      `${PREFIX7}list`,
      { ...params, pagesize },
      { load: true }
    );
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 班级学情分析数据
 * @Author: Dyf
 * @param {object} params
 * @return {*}
 */
export async function $studyAnalysis(params) {
  try {
    return await HTTP.post(`${PREFIX7}study_analysis`, params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 班级学生详情
 * @Author: Dyf
 * @param {number} stuser_id 学生id
 * @return {*}
 */
export async function $studentDetail(stuser_id) {
  try {
    return await HTTP.post(`${PREFIX7}info`, { stuser_id });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 学生信息编辑
 * @Author: Dyf
 * @param {object} params
 * @return {*}
 */
export async function $studentEdit(params) {
  try {
    return await HTTP.post(`${PREFIX7}edit`, params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 重置学生密码
 * @Author: Dyf
 * @param {number} stuser_id 学生id
 * @return {*}
 */
export async function $studentResetPwd(stuser_id) {
  try {
    return await HTTP.post(`${PREFIX7}reset_pwd`, { stuser_id });
  } catch (error) {
    return error;
  }
}

/* 待处理 */

/*******
 * @Descripttion: 待办事项
 * @Author: Dyf
 * @return {*}
 */
export async function $scheduleList() {
  try {
    return await HTTP.post(`${PREFIX8}receivelist`);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 待处理列表
 * @Author: Dyf
 * @param {Number} params.testu_exa_type 数据类型
 * @param {String} params.stuser_name 学生姓名
 * @param {Number} params.pageindex  当前页码
 * @param {Number} pagesize  每页条数
 * @return {*}
 */
export async function $toDoList(params, pagesize = PAGE_SIZE) {
  try {
    return await HTTP.post(
      `${PREFIX8}list`,
      { ...params, pagesize },
      { load: true }
    );
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 处理学生（接收、退回）
 * @Author: Dyf
 * @param {object} params 请求参数
 * @return {*}
 */
export async function $handlingStudents(params) {
  try {
    return await HTTP.post(`${PREFIX8}receive_return`, params);
  } catch (error) {
    return error;
  }
}
/**
 * 学校升学
 * @param {object} params
 * @returns
 */
export async function upgradation(params) {
  try {
    return await HTTP.post("/school/schclass/upgradation", params);
  } catch (error) {
    return error;
  }
}
